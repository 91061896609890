import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import SearchPosts from "../components/searchPosts"
import Navbar from '../components/navbar'
import SubstackChange1 from "../components/subStackChange"
import SubstackSolo1 from "../components/subStackSolo"

import {FaTwitter} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {FaEnvelope} from "react-icons/fa";
import {FaPinterest} from "react-icons/fa";
import {FaLinkedin} from "react-icons/fa";
import { ShareButtonRectangle, ShareBlockStandard } from "react-custom-share";
import { css } from "emotion";
import HcustomReactShare from "../components/HcustomReactShare";



class Blog extends React.Component {
  render() {
    const { data, navigate, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const localSearchBlog = data.localSearchBlog
    const customStyles = css`
      border-radius: 50% 0 50% 0;
      margin: 0 10px;
      flex-basis: 60px;
      height: 60px;
      flex-grow: 0;
    `;
    const shareBlockProps = {
      url: "https://www.seancheng.me/",
      button: ShareButtonRectangle,
      buttons: [
        { network: "Twitter", icon: FaTwitter },
        { network: "Facebook", icon: FaFacebook },
        { network: "Email", icon: FaEnvelope },
        { network: "Pinterest", icon: FaPinterest },
        { network: "Linkedin", icon: FaLinkedin }
      ],
      text: `Take a look at this blog I recently found`,
      longtext: `Take a look at this blog I recently found`,
      buttonCustomClassName: customStyles
    };

    return (
  
      <Layout location={this.props.location} title={siteTitle}>
      
        <SEO title="All Posts"/>
      
      <SearchPosts
          posts={posts}
          localSearchBlog={localSearchBlog}
          navigate={navigate}
          location={location}
        />

      <p><strong>Get my monthly essay, reading recommendations, and exclusive insights:</strong></p>
       <SubstackSolo1/>

       <Link to="/">
          
        </Link>

        <HcustomReactShare/> {/* This is the home page sharing button */}

      </Layout>
      
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tag
            description
          }
        }
      }
    }
  }
`
