import PropTypes from "prop-types";
import React from "react";
import {FaTwitter} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {FaEnvelope} from "react-icons/fa";
import {FaPinterest} from "react-icons/fa";
import {FaLinkedin} from "react-icons/fa";
import { ShareButtonRectangle, ShareBlockStandard } from "react-custom-share";
import { css } from "emotion";


const HcustomReactShare = props => {
    const { url, title, excerpt } = props;
    const customStyles = css`
      border-radius: 50% 0 50% 0;
      margin: 0 10px;
      flex-basis: 60px;
      height: 60px;
      flex-grow: 0;
    `;

    const shareBlockProps = {
        url: "http://www.seancheng.me/",
        button: ShareButtonRectangle,
        buttons: [
            { network: "Twitter", icon: FaTwitter },
            { network: "Facebook", icon: FaFacebook },
            { network: "Linkedin", icon: FaLinkedin }
        ],
        text: title,
        longtext: excerpt,
        buttonClassName: customStyles
      };
    
      return <ShareBlockStandard {...shareBlockProps} />;
    };
    
    HcustomReactShare.propTypes = { 
        url: PropTypes.string,
        title: PropTypes.string,
        excerpt: PropTypes.string
      };
    HcustomReactShare.defaultProps = {
         url: "https://www.seancheng.me/",
         title: '',
         excerpt: ''
          };
    
    export default HcustomReactShare;